import Vue from "vue";
import VueI18n from "vue-i18n";

Vue.use(VueI18n);

const languages = [
  {
    flag: "fr",
    language: "fr",
    title: "Français",
  },
];

const messages = {
  fr: {
    actions: {
      copy: "Copier le style",
      paste: "Appliquer le style",
      delete: "Supprimer",
    },
    common: {
      save: "Sauvegarder",
      saveAndCreate: "Sauvegarder et créer",
      cancel: "Annuler",
      load: "Charger",
      close: "Fermer",
      or: "OU",
      rowsPerPage: "Lignes par page :",
      tableActions: "Actions",
      allItems: "Tout",
      edit: "Éditer",
      create: "Créer",
      delete: "Supprimer",
      settings: "Paramètres",
      goBack: "Retour",
      createFirstScene: "Créer une première scène",
      noPlaylists: "Aucune playlist",
      duplicate: "Dupliquer",
      copySuffix: " - Copie",
      use: "Utiliser",
      templates: {
        useTemplate: "Utiliser un modèle",
      },
    },
    components: {
      expandableSearchBar: {
        search: "Rechercher",
      },
      mailInput: {
        mailInvalid: "Veuillez entrer une adresse e-mail valide",
        mailPlaceholder: "Adresse e-mail",
      },
      fileDrop: {
        select: "Selectionner ou déposer un fichier...",
      },
      backgroundDisplayer: {
        tab: {
          color: {
            title: "Couleur",
          },
          image: {
            title: "Image",
          },
          video: {
            title: "Vidéo",
          },
        },
      },
      displayElementDeleteDialog: {
        irreversible: "Cette action est irréversible.",
      },
      deletePlaylistDialog: {
        title: "Supprimer la playlist",
        text: "Êtes-vous sûr de vouloir supprimer cette playlist ?",
      },
      deleteSceneDialog: {
        title: "Supprimer la scène",
        text: "Êtes-vous sûr de vouloir supprimer cette scène ?",
      },
      moduleSelector: {
        searchModule: "Rechercher un module",
      },
      navigationButtons: {
        enableEditMode: "Passer en mode édition",
        disableEditMode: "Quitter le mode édition",
        backgroundButton: "Changer le fond d'écran",
        addScene: "Créer une nouvelle scène",
      },
      sitesSelector: {
        searchSite: "Rechercher un site",
        goTo: "Accéder au site",
        noSites: "Aucun site à afficher",
        table: {
          siteName: "Nom",
        },
      },
      draggableSceneList: {
        searchScene: "Rechercher une scène",
      },
      displayElementInList: {
        setAsDefault: "Définir comme principale",
      },
      sceneList: {
        deleteScene: "Supprimer la scène",
        isDefault: "Scène principale",
        confirmMessage: "Supprimer définitivement la scène ?",
        errors: {
          loadScenesFailed:
            "Les scènes n'ont pas pu être récupérées. Veuillez rééssayer plus tard.",
        },
      },
      saveScenesMenu: {
        saveScene: "Sauvegarder la scène",
        newSceneTitle: "Titre de la nouvelle scène",
      },
      startHelpMenu: {
        addTotalProduction: "Ajouter un module 'Production totale'",
        addText: "Ajouter un module 'Texte'",
        displayMenu:
          "Afficher la liste des modules en cliquant sur le bouton 'Menu'",
      },
      playlistSegment: {
        withoutTransition: "Sans transition (à venir)",
        seconds: "secondes",
      },
      playlistTimeline: {
        dragScene: "Glissez une scène ici",
      },
      modules: {
        categories: {
          solar: "Modules solaires",
          generic: "Modules génériques",
        },
        instantPower: {
          title: "Puissance instantanée",
          description: "Affiche la puissance actuelle de la centrale",
        },
        dayEnergy: {
          title: "Production du jour",
          description:
            "Affiche l'énergie produite par la centrale depuis le début de la journée",
        },
        dailyConsumption: {
          title: "Consommation du jour",
          description:
            "Affiche la consommation du site depuis le début de la journée",
        },
        monthEnergy: {
          title: "Production du mois",
          description:
            "Affiche l'énergie produite par la centrale depuis le début du mois",
        },
        yearEnergy: {
          title: "Production de l'année",
          description:
            "Affiche l'énergie produite par la centrale depuis le début de l'année",
        },
        allTimeEnergy: {
          title: "Production totale",
          description:
            "Affiche la production d'énergie de la centrale depuis sa mise en service",
        },
        carbon: {
          title: "CO2 évité",
          description:
            "Affiche la quantité de CO2 évitée grâce à la production de la centrale, depuis sa mise en service",
        },
        tree: {
          title: "Arbres plantés",
          description:
            "Affiche l'équivalent en arbres plantés grâce à la production d'énergie de la centrale, depuis sa mise en service",
        },
        autoConsumption: {
          title: "Autoconsommation",
          description:
            "Affiche quel pourcentage d'énergie produite est consommée par le site",
        },
        autoProduction: {
          title: "Autoproduction",
          description:
            "Affiche quel pourcentage de la consommation totale du site est couvert par l'énergie produite",
        },
        battery: {
          title: "Charge de la batterie",
          description: "Affiche le niveau de charge de la batterie",
        },
        color: {
          title: "Couleur",
          description: "Affiche une couleur",
        },
        text: {
          title: "Texte",
          default: "Texte",
          description: "Affiche un texte libre",
        },
        image: {
          title: "Image",
          dragFileText: "ou faites glisser une image ici",
          selectFileButton: "Selectionnez",
          description: "Affiche une image",
        },
        clock: {
          title: "Horloge",
          description: "Affiche l'heure actuelle",
        },
      },
      configMenu: {
        groups: {
          general: "Général",
          title: "Titre",
          data: "Données",
        },
        parameters: {
          titleContent: "Titre",
          titleFontSize: "Taille de police du titre",
          titleFontColor: "Couleur du titre",
          titleAlignment: "Alignement du titre",
          titleIndentation: "Indentation du titre",
          contentFontSize: "Taille de police du contenu",
          contentFontColor: "Couleur",
          contentAlignment: "Alignement du texte",
          width: "Largeur",
          height: "Hauteur",
          posX: "Position horizontale",
          posY: "Position verticale",
          opacity: "Opacité",
          backgroundColor: "Couleur de fond",
          textFontSize: "Taille de police du texte",
          textFontColor: "Couleur du texte",
          mainProgressColor: "Couleur de jauge",
          secondProgressColor: "Couleur de fond de jauge",
          progressWidth: "Épaisseur du cercle",
          zIndex: "Profondeur",
          zIndexActions: {
            moveForward: "Avancer",
            moveBackward: "Reculer",
            sendToFront: "Envoyer au premier plan",
            sendToBack: "Envoyer en arrière-plan",
          },
          unit: "Unité",
          whConverter: "Unité",
          wConverter: "Unité",
          textContent: "Texte",
          elevation: "Ombre portée",
          borderRadius: "Arrondi des coins",
          image: "Image",
        },
      },
      avatarMenu: {
        logout: "Se déconnecter",
      },
      templates: {
        saveTemplateMenu: {
          title: "Sauvegarder le modèle",
          newTemplateTitle: "Titre de du nouveau modèle",
        },
        templateList: {
          deleteDialogTitle: "Supprimer le modèle",
          deleteDialogText: "Êtes-vous sûr de vouloir supprimer ce modèle ?",
        },
        loadTemplateMenu: {
          noTemplates: "Aucun modèle disponible actuellement",
        },
      },
    },
    services: {
      authentication: {
        loginFailed: "Adresse e-mail ou mot de passe incorrect",
        resetPasswordFailed:
          "La réinitialisation n'a pas pu être effectuée. Veuillez rééssayer plus tard.",
      },
      sites: {
        siteNotFound: "Le site selectionné n'est pas accessible.",
        fetchFailed: "Les sites accessibles n'ont pas pu être récupérés.",
        changedDefaultScene: "La scène principale a été correctement définie.",
        changedDefaultPlaylist:
          "La playlist principale a été correctement définie.",
        setDefaultSceneFailed: "La scène principale n'a pas pu être définie.",
        setDefaultPlaylistFailed:
          "La playlist principale n'a pas pu être définie.",
        loadScenesFailed:
          "Les scènes n'ont pas pu être récupérées. Veuillez rééssayer plus tard.",
      },
      scenes: {
        saveFailed:
          "La scène n'a pas pu être sauvegardée. Veuillez rééssayer plus tard.",
        saveSuccess: "La scène a été sauvegardée.",
        deleteFailed:
          "La scène n'a pas pu être supprimée. Veuillez rééssayer plus tard.",
        deleteSuccess: "La scène a été supprimée.",
      },
      playlists: {
        confirmDeletion: "La playlist a été supprimée.",
        deletePlaylistFailed: "La playlist n'a pas pu être supprimée.",
        saveSuccess: "La playlist a été sauvegardée.",
        saveFailed: "La playlist n'a pas pu être sauvegardée.",
        loadFailed: "La playlist n'a pas pu être récupérée.",
      },
      templates: {
        saveSuccess: "Le modèle a été sauvegardé.",
        saveFailed: "Le modèle n'a pas pu être sauvegardé.",
        deleteSuccess: "Le modèle a été supprimé.",
        deleteFailed: "Le modèle n'a pas pu être supprimé.",
      },
    },
    views: {
      home: {
        title: "Accueil",
        launchDisplay: "Lancer le mode affichage",
        latestModifiedScenes: "Dernières scènes modifiées",
        latestModifiedPlaylists: "Dernières playlists modifiées",
        viewAllScenes: "Voir toutes les scènes",
        viewAllPlaylists: "Voir toutes les playlists",
        onboardWelcome: "Bienvenue dans votre espace de création",
        onboardQuote:
          "Affichez et valorisez l’impact de votre centrale solaire",
      },
      reset: {
        title: "Réinitialisation de mot de passe",
        hint: "Pour assurer la sécurité de votre compte, veuillez choisir un nouveau mot de passe contenant au moins :\n\n- {length} caractères\n- une lettre majuscule\n- une lettre minuscule\n- un chiffre",
        passwordPlaceholder: "Nouveau mot de passe",
        confirmPasswordPlaceholder: "Confimez votre nouveau mot de passe",
        confirmReset: "Réinitialiser",
        form: {
          passwordRequired: "Le mot de passe ne peut pas être vide",
          passwordLengthRule:
            "Le mot de passe doit avoir au moins {length} caractères",
          passwordUppercaseRule:
            "Le mot de passe doit contenir une lettre majuscule",
          passwordLowercaseRule:
            "Le mot de passe doit contenir une lettre minuscule",
          passwordNumberRule: "Le mot de passe doit contenir un chiffre",
          passwordMatchRule: "Les mots de passe ne correspondent pas",
        },
      },
      requestReset: {
        title: "Mot de passe oublié ?",
        hint: "Entrez votre adresse e-mail. Nous vous enverrons les indications pour réinitialiser votre mot de passe.",
        continue: "Continuer",
      },
      requestResetSuccess: {
        title: "Demande de réinitialisation effectuée",
        hint: "Votre demande de réinitialisation de mot de passe a été prise en compte. Les instructions pour réinitialiser votre mot de passe ont été envoyées à l'adresse e-mail que vous avez fournie. Veuillez vérifier votre boîte de réception (y compris le dossier de courrier indésirable) pour trouver l'e-mail de réinitialisation. Suivez les instructions fournies pour choisir un nouveau mot de passe.",
        goToLogin: "Retour à la page de connexion",
      },
      resetPasswordSuccess: {
        title: "Réinitialisation effectuée",
        hint: "Votre mot de passe a été réinitialisé avec succès. Vous pouvez maintenant vous connecter à votre compte en utilisant votre nouveau mot de passe.",
        goToLogin: "Retour à la page de connexion",
      },
      login: {
        title: "Se connecter à Solar On TV",
        passwordPlaceholder: "Mot de passe",
        login: "Se connecter",
        forgotPassword: "Mot de passe oublié ?",
        form: {
          passwordRequired: "Le mot de passe ne peut pas être vide",
        },
      },
      scene: {
        currentSite: "Centrale solaire",
        logout: "Se déconnecter",
      },
      settings: {
        sites: "Sites",
      },
      playlistDetail: {
        play: "Lire",
        newPlaylistTitle: "Titre de la nouvelle playlist",
      },
      playlistList: {
        title: "Playlists",
        viewDetail: "Voir le détail",
        createPlaylist: "Créer une première playlist",
        isDefault: "Playlist principale",
      },
      templateList: {
        title: "Modèles",
        noTemplate: "Aucun modèle",
      },
      display: {
        loadScene: "Charger une scène",
        duplicateScene: "Dupliquer une scène",
        loadPlaylists: "Charger une playlist",
        leaveDisplayView: "Quitter le mode affichage",
        sceneQuickStartTitle: "Démarrage rapide",
        defineSceneAsDefault: "Définir une scène principale",
        definePlaylistAsDefault: "Définir une playlist principale",
        quickStart: {
          title: "Mode affichage",
          purpose:
            "Cette page affichera par défaut votre scène ou playlist principale.",
          preIconHint:
            "Pour définir l'élément à afficher, vous pouvez cliquer sur l'icone",
          postIconHint: "de ce dernier.",
        },
        sceneDuplicated: "La scène a été dupliquée",
      },
      sceneList: {
        title: "Scènes",
        noScene: "Aucune scène",
      },
    },
  },
};

const i18n = new VueI18n({
  locale: "fr",
  fallbackLocale: "fr",
  messages,
});

export { languages, i18n };
