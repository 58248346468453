import { BackgroundConfig } from "@/models/background/background-config.model";
import { IModuleConfigBase } from "@/models/modules/imodule-config-base";
import { Media } from "../files/media.model";
import { ModuleTypes } from "../modules/modules.enum";
import { loadMediaToElement } from "@/utils/media.utils";
import { IDisplayElement } from "../display-elements/display-element.interface";
import { DisplaySegment } from "../display-elements/display-segment.interface";
import { BackgroundTypes } from "../background/background.enum";
import { ImageModuleConfig } from "@/components/modules/image-module/image-module.config";

export class Scene implements IDisplayElement {
  id!: string;
  title!: string;
  modules: IModuleConfigBase[] = [];
  background = new BackgroundConfig();
  medias: Media[] = [];
  modificationDate!: string;
  siteId!: string;

  isLoaded = false;
  private awaitedLoadCount = 0;
  private loadedCallback: (() => void) | null = null;
  private errorCallback: (() => void) | null = null;

  constructor(init?: Partial<Scene>) {
    Object.assign(this, init);

    this.loadMedias();
  }

  public init() {
    this.awaitedLoadCount = this.getAwaitingCount();
    this.loadedCallback = null;
    this.errorCallback = null;
    this.isLoaded = this.awaitedLoadCount === 0;
  }

  public markAsLoaded(): void {
    this.awaitedLoadCount--;

    if (this.awaitedLoadCount <= 0) {
      this.loadedCallback && this.loadedCallback();
      this.isLoaded = true;
    }
  }

  public markAsError(): void {
    if (this.errorCallback) {
      this.errorCallback();
    }
  }

  public onLoaded(): Promise<void> {
    return new Promise((resolve, reject) => {
      this.loadedCallback = resolve;
      this.errorCallback = reject;
    });
  }

  public duplicate(newTitle: string): void {
    this.id = "";
    this.title = newTitle;
  }

  getNextSegment(_: DisplaySegment | null): DisplaySegment {
    return {
      duration: 0,
      index: 0,
      scene: this,
    };
  }

  private loadMedias(): void {
    loadMediaToElement(this.background, this.medias);

    this.modules.forEach((module) => {
      if (module.type === ModuleTypes.Image) {
        module.loadMedias(this.medias);
      }
    });
  }

  /**
   * Get the number of medias that are not loaded yet.
   * If the background is an image, it is also counted.
   * If the image is not set, it is not counted.
   */
  private getAwaitingCount(): number {
    let count = this.modules.filter((m) => {
      if (m.type !== ModuleTypes.Image) {
        return false;
      }

      const imageModule = m as ImageModuleConfig;

      return imageModule.image.value;
    }).length;

    if (this.background.backgroundType === BackgroundTypes.Image) {
      count++;
    }

    return count;
  }
}
