export enum ModuleTypes {
  InstantPower = "instantPower",
  DayEnergy = "dayEnergy",
  MonthEnergy = "monthEnergy",
  YearEnergy = "yearEnergy",
  AllTimeEnergy = "allTimeEnergy",
  DailyConsumption = "dailyConsumption",
  Carbon = "carbon",
  Tree = "tree",
  AutoConsumption = "autoConsumption",
  AutoProduction = "autoProduction",
  Battery = "battery",
  Color = "color",
  Text = "text",
  Image = "image",
  Clock = "clock",
}
