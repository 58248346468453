import { getLightSceneFromDTO } from "@/dto/mappers/scene-mapper.utils";
import { LightSceneDTO } from "@/dto/scenes/light-scene.dto";
import { Scene } from "@/models/scenes/scene.model";
import { Site, SiteScene } from "@/models/sites/site.model";
import { ElementType } from "@/models/element-type.enum";
import store from "@/store";
import { ApiClientBase } from "./api-client-base";

export default class SitesApiClient extends ApiClientBase {
  constructor() {
    super("sites");
  }

  async getSites(): Promise<Site[]> {
    const data = await this.get<Site[]>(
      this.urlBuilder.withErrorMessage("services.sites.fetchFailed")
    );
    return data;
  }

  async getSite(siteId: string): Promise<SiteScene> {
    const data = await this.get<SiteScene>(
      this.urlBuilder
        .withFragment(siteId)
        .withErrorMessage("services.sites.siteNotFound")
    );

    return data;
  }

  async getScenesForSite(
    id: string,
    limit = 0,
    shouldBeSorted = false
  ): Promise<Scene[]> {
    const data = await this.get<LightSceneDTO[]>(
      this.urlBuilder
        .withFragment(id)
        .withFragment("scenes")
        .withLimit(limit)
        .withOptionalQueryParameter("order", "desc", shouldBeSorted)
        .withErrorMessage("services.sites.loadScenesFailed")
    );

    return data.map((s: LightSceneDTO) => getLightSceneFromDTO(s));
  }

  async setDefaultElement(
    siteId: string,
    elementId: string,
    elementType: ElementType
  ): Promise<void> {
    const successKey =
      elementType === ElementType.scene
        ? "services.sites.changedDefaultScene"
        : "services.sites.changedDefaultPlaylist";

    const errorKey =
      elementType === ElementType.scene
        ? "services.sites.setDefaultSceneFailed"
        : "services.sites.setDefaultPlaylistFailed";

    await this.post(
      this.urlBuilder
        .withFragment(siteId)
        .withFragment("default")
        .withSuccessMessage(successKey)
        .withErrorMessage(errorKey),
      {
        id: elementId,
        type: elementType,
      }
    );

    store.dispatch("siteModule/changeDefaultElement", {
      id: elementId,
      type: elementType,
    });
  }
}
