import axios from "axios";
import store from "@/store/index";
import AuthenticationApiClient from "@/api/authentication-client.api";

export function jwtInterceptor() {
  axios.interceptors.request.use((request) => {
    const token = store.getters["userModule/jwtToken"];

    if (token && request.headers) {
      request.headers.Authorization = `Bearer ${token}`;
    }

    return request;
  });
}

let isRefreshing = false;
let failedQueue: any[] = [];

const processQueue = (error: any = null) => {
  failedQueue.forEach((prom) => {
    if (error) {
      prom.reject(error);
    } else {
      prom.resolve();
    }
  });

  failedQueue = [];
};

const addToQueue = (prom: any) => {
  failedQueue.push(prom);
};

const refreshTokens = () => {
  if (isRefreshing) {
    return new Promise((resolve, reject) => {
      addToQueue({ resolve, reject });
    });
  }

  isRefreshing = true;

  return new Promise<void>((resolve, reject) => {
    const refreshToken = store.getters["userModule/refreshToken"];

    new AuthenticationApiClient()
      .refresh({
        refreshToken: refreshToken,
      })
      .then((tokens) => {
        store.dispatch("userModule/refreshTokens", tokens);
        isRefreshing = false;
        processQueue();
        resolve();
      })
      .catch((err) => {
        isRefreshing = false;
        store.dispatch("userModule/successLogout");
        processQueue(err);
        reject(err);
      });
  });
};

export function authErrorInterceptor() {
  axios.interceptors.response.use(
    (response) => response,
    async (error) => {
      const {
        config,
        response: { status },
      } = error;

      if (status === 401) {
        await refreshTokens();
        return await axios(config);
      }

      return Promise.reject(error);
    }
  );
}
