import { ModuleConfigBase } from "@/models/modules/module-base.config";
import { ModuleCategories } from "@/models/modules/module-categories.enum";
import { ModuleTypes } from "@/models/modules/modules.enum";
import { ParametersGroup } from "../parameters-group";
import { DataPartConfig } from "../parts/data-part/data-part.config";

export class ClockModuleConfig extends ModuleConfigBase {
  category = ModuleCategories.Generic;

  textPart = new DataPartConfig();

  constructor() {
    super(ModuleTypes.Clock);
  }

  getContentParameters(): ParametersGroup[] {
    return [this.textPart.getParameterGroup()];
  }
}
