import { NotificationLevel } from "@/models/notification-level.enum";
import _Vue from "vue";
import { Store } from "vuex";

export const notifierPlugin = {
  install: (vue: typeof _Vue, store: Store<unknown>) => {
    if (!store) {
      throw new Error("Please provide vuex store.");
    }

    vue.prototype.$notifier = {
      showMessage: function (
        message: string,
        level: NotificationLevel,
        timeout = 2500
      ) {
        store.commit(
          "notificationsModule/showMessage",
          { message, level, timeout },
          { root: true }
        );
      },
    };
  },
};
