import Vue from "vue";
import VueRouter, { RouteConfig } from "vue-router";
import store from "@/store/index";
import SitesApiClient from "@/api/sites-client.api";
import AuthenticationApiClient from "@/api/authentication-client.api";

Vue.use(VueRouter);

const loginLayout = () => import("../views/login/LoginLayout.vue");

const routes: Array<RouteConfig> = [
  {
    path: "/",
    component: () => import("../views/main/MainLayout.vue"),
    children: [
      {
        path: "",
        component: () => import("../views/main/HomeView.vue"),
      },
      {
        path: "scene",
        component: () => import("../views/main/scene/SceneListView.vue"),
        props: true,
      },
      {
        path: "template",
        component: () => import("../views/main/template/TemplateListView.vue"),
        props: true,
      },
      {
        path: "playlist",
        component: () => import("../views/main/playlist/PlaylistListView.vue"),
        props: true,
      },
      {
        path: "playlist/new",
        component: () =>
          import("../views/main/playlist/PlaylistDetailView.vue"),
        props: true,
      },
      {
        path: "playlist/:id",
        component: () =>
          import("../views/main/playlist/PlaylistDetailView.vue"),
        props: true,
      },
    ],
  },
  {
    path: "/display",
    name: "display",
    component: () => import("../views/DisplayView.vue"),
  },
  {
    path: "/select-site",
    name: "select-site",
    component: () => import("../views/SelectSiteView.vue"),
  },
  {
    path: "/login",
    component: loginLayout,
    children: [
      {
        name: "login",
        path: "",
        component: () => import("../views/login/LoginView.vue"),
      },
    ],
  },
  {
    path: "/reset-password",
    component: loginLayout,
    children: [
      {
        path: "request",
        name: "request",
        component: () => import("../views/login/RequestPasswordResetView.vue"),
      },
      {
        path: "request-success",
        name: "request-success",
        component: () =>
          import("../views/login/RequestPasswordResetSuccessView.vue"),
      },
      {
        path: "reset",
        name: "reset",
        component: () => import("../views/login/PasswordResetView.vue"),
      },
      {
        path: "reset-success",
        name: "reset-success",
        component: () => import("../views/login/PasswordResetSuccessView.vue"),
      },
    ],
  },
  {
    path: "/settings",
    name: "settings",
    component: () => import("../views/SettingsView.vue"),
  },
];

const router = new VueRouter({
  routes,
});

router.beforeEach(async (to, from, next) => {
  const publicPages = [
    "/login",
    "/reset-password/request",
    "/reset-password/request-success",
    "/reset-password/reset",
    "/reset-password/reset-success",
  ];
  const authRequired = !publicPages.includes(to.path);
  const isLoggedIn = store.getters["userModule/isLoggedIn"];

  if (!isLoggedIn) {
    authRequired ? next("/login") : next();
    return;
  }

  const hasSiteDetails = store.getters["siteModule/isLoaded"];

  if (!hasSiteDetails) {
    const selectedSite = store.getters["siteModule/getSelectedSiteId"];

    if (selectedSite) {
      const sitesApiClient = new SitesApiClient();
      const authenticationApiClient = new AuthenticationApiClient();

      const currentUser = await authenticationApiClient.getCurrentUser();
      const siteDetails = await sitesApiClient.getSite(selectedSite);

      store.dispatch("siteModule/loadSiteDetails", siteDetails);
      store.dispatch("userModule/loadUser", currentUser);
    }
  }

  !authRequired ? next("/") : next();
});

export default router;
