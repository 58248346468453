import { RefreshedTokens } from "@/models/user/refresh-token";
import { TokenUser } from "@/models/user/token-user.model";
import router from "@/router";
import { ActionTree, GetterTree, Module, MutationTree } from "vuex";
import { RootState } from ".";
import { RoleUser } from "@/models/user/role-user.interface";
import { UserRole } from "@/models/user-role.enum";

const refreshToken = localStorage.getItem("refreshToken");

export interface UserState {
  token: string | undefined;
  refreshToken: string | undefined;
  userName: string | undefined;
  isAppAdmin: boolean;
}

export const state: UserState = {
  token: undefined,
  refreshToken: refreshToken ?? undefined,
  userName: undefined,
  isAppAdmin: false,
};

const actions: ActionTree<UserState, RootState> = {
  loadUser({ commit }, user: RoleUser) {
    commit("setUserName", user.name);
    commit("setIsAppAdmin", user.roles.includes(UserRole.Admin));
  },
  successLogin({ dispatch }, user: TokenUser) {
    dispatch("refreshTokens", user.refreshedTokens);
    dispatch("loadUser", user);
  },
  refreshTokens({ state }, newTokens: RefreshedTokens) {
    state.token = newTokens.accessToken;
    state.refreshToken = newTokens.refreshToken;

    localStorage.setItem("refreshToken", state.refreshToken);
  },
  successLogout({ state, dispatch }) {
    state.token = undefined;
    state.refreshToken = undefined;
    localStorage.removeItem("refreshToken");

    dispatch("siteModule/onLogout", undefined, { root: true });

    router.push("/login");
  },
};

const mutations: MutationTree<UserState> = {
  setUserName(state, name: string) {
    state.userName = name;
  },
  setIsAppAdmin(state, isAdmin: boolean) {
    state.isAppAdmin = isAdmin;
  },
};

const getters: GetterTree<UserState, RootState> = {
  jwtToken(state): string | undefined {
    return state.token;
  },
  refreshToken(state): string | undefined {
    return state.refreshToken;
  },
  isLoggedIn(state): boolean {
    return !!state.token || !!state.refreshToken;
  },
  getUserName(state): string {
    return state.userName ?? "";
  },
  isAppAdmin(state): boolean {
    return state.isAppAdmin;
  },
};

export const profile: Module<UserState, RootState> = {
  namespaced: true,
  state: state,
  getters: getters,
  actions: actions,
  mutations: mutations,
};
